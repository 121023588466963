<template>

	
	<div class="cont" id="p10">
		<div class="page-title">{{ program.name }}</div>
		<div class="c-list">
			<div class="c01">
				<div class="s01">
					<div class="s-item">
						<div class="s-header">
							<div class="s-title">신청 구분</div>
						</div>
						<div class="s-body">
							<div class="s-content w100">
								<div class="si-title">신청구분</div>
								<div class="si-desc setting">
									<div class="cont-radio"
										v-for="(type, index) in companyType"
										:key="index"
									>
										<input type="radio" name="radio_status1"
											v-model="item.companyType"
											:value="type.code"
											:id="'rd0' + index"
										>
										<label :for="'rd0' + index">{{ type.name }}</label>
									</div>
								</div>
							</div>
							<div class="s-content w100">
								<div class="si-title">아이디</div>
								<div class="si-desc setting">
									<div class="cont-radio">
										<input type="radio" id="rd04" name="radio_status2" value="true" checked
											v-model="item.isAutoUserId">
										<label for="rd04">자동생성</label>
									</div>
									<div class="cont-radio">
										<input type="radio" id="rd05" name="radio_status2" value="false"
											v-model="item.isAutoUserId"
										>
										<label for="rd05">직접입력</label>
									</div>
									<div class="dt">
										<input class="w150p" type="text" placeholder="요청 아이디 입력"
											v-model="item.userId"
										>
										<a @click="idCheck(item.userId)">
											<div class="btn-type01"
											:class="idCheckStatus"
											>중복체크</div></a>
										<span class="err_id active"
											v-show="idCheck.result">사용중인 아이디입니다.</span> <!-- error시 class "active" -->
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="s-item corporate_business active"> <!-- 법인/개인 사업자 class "active" -->
						<div class="s-header">
							<div class="s-title">하위 대리점 정보</div>
						</div>
						<div class="s-body">
							<div class="s-content">
								<div class="si-title">상호<span class="em">*</span></div>
								<div class="si-desc">
									<input type="text" placeholder="상호명 입력"
										v-model="item.businessName"
										maxlength="50"
									>
								</div>
							</div>
							<div class="s-content">
								<div class="si-title"></div>
								<div class="si-desc">
								</div>
							</div>
						</div>
						
						<div class="s-body"
							v-if="item.companyType != 'NON'"
						>
							<div class="s-content">
								<div class="si-title">사업자등록번호<span class="em">*</span></div>
								<div class="si-desc">
									<input type="number" placeholder="사업자등록번호 입력"
										v-model="item.businessNumber"
										:rules="[rules.max(item, 'businessNumber', 10)]"
									>
								</div>
							</div>
							<div class="s-content">
								<div class="si-title">법인등록번호</div>
								<div class="si-desc">
									<input type="number" placeholder="법인등록번호 입력"
										v-model="item.corporateNumber"
										:rules="[rules.max(item, 'corporateNumber', 13)]"
									>
								</div>
							</div>
							<div class="s-content">
								<div class="si-title">업태</div>
								<div class="si-desc">
									<input type="text" placeholder="업태 입력"
										v-model="item.businessCate"
										maxlength="10"
									>
								</div>
							</div>
							<div class="s-content">
								<div class="si-title">종목</div>
								<div class="si-desc">
									<input type="text" placeholder="종목 입력"
										v-model="item.businessType"
										maxlength="10"
									>
								</div>
							</div>
						</div>
						<div class="s-body">
							<div class="s-content">
								<div class="si-title">대표전화</div>
								<div class="si-desc">
									<input type="number" placeholder="-를 제외한 숫자 입력"
										v-model="item.phoneNumber"
										:rules="[rules.max(item, 'phoneNumber', 12)]"
									>
								</div>
							</div>
							<div class="s-content">
								<div class="si-title">팩스</div>
								<div class="si-desc">
									<input type="number" placeholder="-를 제외한 숫자 입력"
										v-model="item.faxNumber"
										:rules="[rules.max(item, 'faxNumber', 12)]"
									>
								</div>
							</div>
							<div class="s-content w100">
								<div class="si-title">사업장 주소</div>
								<div class="si-desc addr">
									<a @click="daumPost('company')"><div class="btn-type01 info">주소검색</div></a>
									<input class="addr1" type="text" placeholder="우편번호"
										v-model="item.zipCode"
										@click="daumPost('company')"
										readonly
									>
									<input class="addr2" type="text" placeholder="주소"
										v-model="item.addressMain"
										@click="daumPost('company')"
										readonly
									>
									<input class="addr3" type="text" placeholder="상세주소를 입력하세요."
										v-model="item.addressDetail"
										maxlength="50"
									>
								</div>
							</div>
						</div>
					</div>
					
					<div class="s-item corporate_business active"> <!-- 법인/개인 사업자 class "active" -->
						<div class="s-header">
							<div class="s-title">대표자 정보</div>
						</div>
						<div class="s-body">
							<div class="s-content">
								<div class="si-title">대표자명<span class="em">*</span></div>
								<div class="si-desc">
									<input type="text" placeholder="대표자명 입력"
										v-model="item.ceoName"
										maxlength="50"
									>
								</div>
							</div>
							
							<div class="s-content">
								<div class="si-title">대표자 휴대폰<span class="em">*</span></div>
								<div class="si-desc">
									<select name="" id=""
										v-model="phoneNumbers.ceo.fir"
									>
										<option
											v-for="(number, index) in numbers"
											:key="index"
											:value="number"
										>{{ number }}</option>
									</select>
									<input class="w110p" type="number" placeholder="" 
										v-model="phoneNumbers.ceo.sec"
										style="padding: 6px 0px 7px !important; width: 100px !important; margin-left: 3px ;"
										maxlength="4"
										:rules="[rules.max(phoneNumbers.ceo, 'sec', 4)]"
									>
									<input class="w110p" type="number" placeholder=""
										v-model="phoneNumbers.ceo.thi"
										style="padding: 6px 0px 7px !important; width: 100px !important; margin-left: 3px ;"
										:rules="[rules.max(phoneNumbers.ceo, 'thi', 4)]"
									>
								</div>
							</div>
							
						</div>
						<div class="s-body"
							v-if="item.companyType == 'NON'"
						>
							<div class="s-content w100">
								<div class="si-title">주민등록번호<span class="em">*</span></div>
								<div class="si-desc">
									<input type="number" placeholder="주민등록번호 앞 6자리 (숫자만 입력하세요)"
										v-model="personalNumber.fir"
										:rules="[rules.max(personalNumber, 'fir', 6)]"
										style="width: 45.5%; margin-right: 5px;"
										>
									<input type="password" placeholder="주민등록번호 뒷 7자리 (숫자만 입력하세요)"
										v-model="personalNumber.sec"
										:rules="[rules.max(personalNumber, 'sec', 7), rules.numeric(personalNumber, 'sec')]"
										style="width: 53.7%;"
										>
								</div>
							</div>
							<div class="s-content w100">
								<div class="si-title">자택 주소</div>
								<div class="si-desc addr">
									<a @click="daumPost('company')"><div class="btn-type01 info">주소검색</div></a>
									<input class="addr1" type="text" placeholder="우편번호"
										v-model="item.homeZipcode"
										@click="daumPost('personal')"
										readonly
									>
									<input class="addr2" type="text" placeholder="주소"
										v-model="item.homeAddressMain"
										@click="daumPost('personal')"
										readonly
									>
									<input class="addr3" type="text" placeholder="상세주소를 입력하세요."
										v-model="item.homeAddressDetail"
										maxlength="50"
									>
								</div>
							</div>
						</div>
					</div>
					
					<div class="s-item">
						<div class="s-header">
							<div class="s-title">담당자 정보</div>
						</div>
						<div class="s-body">
							<div class="s-content">
								<div class="si-title">담당자</div>
								<div class="si-desc">
									<input type="text" placeholder="담당자 입력">
								</div>
							</div>
							<div class="s-content">
								<div class="si-title">담당자 휴대폰</div>
								<div class="si-desc">
									<select name="" id=""
										v-model="phoneNumbers.manager.fir"
									>
										<option
											v-for="(number, index) in numbers"
											:key="index"
											:value="number"
										>{{ number }}</option>
									</select>
									<input class="w110p" type="number" placeholder="" 
										v-model="phoneNumbers.manager.sec"
										style="padding: 6px 0px 7px !important; width: 100px !important; margin-left: 3px ;"
										maxlength="4"
										:rules="[rules.max(phoneNumbers.manager, 'sec', 4)]"
									>
									<input class="w110p" type="number" placeholder=""
										v-model="phoneNumbers.manager.thi"
										style="padding: 6px 0px 7px !important; width: 100px !important; margin-left: 3px ;"
										@change="phoneNumbers.manager[2].substring(0, 4)"
										:rules="[rules.max(phoneNumbers.manager, 'thi', 4)]"
									>
								</div>
							</div>
							<div class="s-content">
								<div class="si-title">이메일</div>
								<div class="si-desc">
									<input type="mail" placeholder="이메일 입력"
										v-model="item.managerEmail"
										maxlength="50"
										:rules="[rules.email(item, 'managerEmail')]"
										:class="{ error: errors.email }"
									>
								</div>
							</div>
							<div class="s-content">
								<div class="si-title" style="visibility: hidden;">공백</div>
								<div class="si-desc" style="visibility: hidden;">
									<input type="text" placeholder="공백">
								</div>
							</div>
						</div>
					</div>
					<div class="s-item">
						<div class="s-header">
							<div class="s-title wauto">서비스 설정 및 계좌 설정</div>
                            <div class="s-link">※1일에서 말일 결제 금액에 대한 수수료 정산은 매달 5일에 일괄 처리됩니다.</div>
						</div>
						<div class="s-body">
							<div class="s-content w100">
								<div class="si-title">인증(온라인)</div>
								<div class="si-desc flex">
									<input class="w160p" type="number" placeholder="수수료 입력"
										v-model="item.serviceItems[0].feeRate"
										:rules="[rules.demical(item.serviceItems[2], 'feeRate', {min: 2, max: 2})]"
									>
									<span class="p">%<span class="ex1">숫자 및 소수점 두자리까지만 입력(예:3.56)</span></span>
								</div>
							</div>
							<div class="s-content w100">
								<div class="si-title">OFF PF(단말기)</div>
								<div class="si-desc flex">
									<input class="w160p" type="text" placeholder="수수료 입력"
										v-model="item.serviceItems[1].feeRate"
										:rules="[rules.demical(item.serviceItems[2], 'feeRate', {min: 2, max: 2})]"
									>
									<span class="p">%</span>
								</div>
							</div>
							<div class="s-content w100">
								<div class="si-title">비인증(수기)</div>
								<div class="si-desc flex">
									<input class="w160p" type="text" placeholder="수수료 입력"
										v-model="item.serviceItems[2].feeRate"
										:rules="[rules.demical(item.serviceItems[2], 'feeRate', {min: 2, max: 2})]"
									>
									<span class="p">%</span>
								</div>
							</div>
							
							<div class="s-content w33">
								<div class="si-title">거래은행<span class="em">*</span></div>
								<div class="si-desc">
									<select name="" id="" class="w100"
										v-model="item.bankCode"
									>
										<option value="">은행 선택</option>
										<option 
											v-for="(code, index) in codes.bankCodes"
											:key="index"
											:value="code.code"
											>{{code.codeName}}</option>
									</select>
								</div>
							</div>
							<div class="s-content w33">
								<div class="si-title">계좌번호<span class="em">*</span></div>
								<div class="si-desc">
									<input type="number" placeholder="-를 제외한 숫자 입력"
										v-model="item.bankAccountNumber"
										:rules="[rules.max(item, 'bankAccountNumber', 14)]"
									>
								</div>
							</div>
							<div class="s-content w33">
								<div class="si-title">예금주<span class="em">*</span></div>
								<div class="si-desc">
									<input type="text" placeholder="예금주 입력"
										v-model="item.bankAccountHolder"
										maxlength="12"
									>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="s04">
					<a @click="save" class="focus"><div class="btn-submit" :class="{active: isSave}">등록</div></a>
				</div>
				
			</div>
		</div>
		
		<DaumPost
			:overlay="daumPostUp"
			:config="daumConfig"

			@callBack="addPost"
		></DaumPost>
		
		<AgentPopupItem 
			v-if="showPopup"
			:item="result_item"

			@setOverlay="setOverlay"
		/>
	</div>
</template>

<script>
	
	import { Axios } from '@/resources/axios/axios'
	import { rules } from '@/resources/rules/rules'

	import DaumPost from '@/components/Daum/DaumPost'
	import AgentPopupItem from '@/view/AgentSales/AgentPopupItem'
	
	export default{
		name: "AgentItem"
		,props: ['user', 'overlay', 'codes']
		,components: { DaumPost, AgentPopupItem }
		,data: function(){
			return {
				program: {
					name: "하위 대리점 등록"
				}
				,result_item: {
				}
				,item: {
					companyType: 'PUBLIC'
					,isAutoUserId: true
					,idCheck: {
						result: false
					}
					,userId: ''
					,bankCode: ''
					,serviceItems: [
						{ feeRate: 0, payChnCate: '001'}
						,{ feeRate: 0, payChnCate: '002'}
						,{ feeRate: 0, payChnCate: '003'}
					]
				}
				,managerMobilPhoneNumber: []
				,index: this.$route.params.index
				,numbers: ['010', '011', '019']
				,companyType: [{ code: 'PUBLIC', name: '법인 사업자'}, { code: 'PRIVATE', name: '개인 사업자'}, { code: 'NON', name: '비사업자'}]
				,isSave: true
				,showPopup: false
				,daumPostUp: false
				,phoneNumbers: {
					ceo: {
						fir: '010', sec: '', thi: ''
					}
					,manager: {
						fir: '010', sec: '', thi: ''
					}
				}
				,personalNumber: {
					fir: '', sec: ''
				}
				,serviceItems: {
					fir: '', sec: '', thi: ''
				}
				,errors: {
					email: false
				}
				,rules: rules
				
				,daumConfig: {
					post: ''
					,address: ''
					,detail: ''
				}
			}
		}
		,computed: {
			idCheckStatus: function(){
				let active = 'ready'
				
				if(this.item.isAutoUserId == false && this.item.userId != ''){
					active = 'active'
				}
				
				if(this.item.idCheck.result){
					active = 'confirm'
				}
				
//				console.log('idCheckActive: ' + active)
				return active
			}
		}
		,methods: {
			getData: async function(){
				if(this.index && this.index != undefined){
					try{
						const result = await Axios({
							method: 'get'
							,url: '/sales/' + this.index
							,authorize: true
						})
						
						if(result.success){
							this.item = result.data.content
						}else{
							this.$emit('setNotify', { type: 'error', message: result.message })
						}
					}catch(E){
						console.log(E)
						this.$emit('setNotify', { type: 'error', message: E })
					}
				}
			}
			,idCheck: async function(userId){
				if(userId){
					try{
						const result = await Axios({
							method: 'get'
							,url: '/users/checked/id/' + userId
							,authorize: true
						})

						if(result.success){
							this.item.idCheck.result = true
						}else{
							this.item.idCheck.result = false
							this.$emit('setNotify', {type: 'error', message: result.message})
						}
					}catch(E){
						console.log(E)
						this.$emit('setNotify', {type: 'error', message: E.status})
					}
				}else{
					this.$emit('setNotify', { type: 'error', message: '아이디를입력하세요'})
				}
			}
			,save: async function(){
				if(this.isSave){
					try{
						const result = await Axios({
							method: 'post'
							,url: '/sales'
							,data: this.item
							,authorize: true
						})
						
						if(result.success){
							this.$emit('setNotify', { type: 'success', message: '대리점이 등록되었습니다.'})
							//this.$router.push('/Agent')
				
							this.$emit('setOverlay')
							this.showPopup = true
							this.result_item = result.data.content
						}else{
							this.$emit('setNotify', { type: 'error', message: result.message})
						}
					}catch(E){
						console.log(E)
						this.$emit('setNotify', { type: 'error', message: E})
					}
				}
			}
			
			,setOverlay: function(){
				this.$emit('setOverlay')
			}
			
			,daumPost: function(type){
				this.$emit('setOverlay')
				this.daumPostUp = true
				if(type == 'company'){
					this.daumConfig.post = 'zipCode'
					this.daumConfig.address = 'addressMain'
				}else{
					this.daumConfig.post = 'zipCode2'
					this.daumConfig.address = 'addressMain2'
				}
			}
			,addPost: function(call){
				this.$set(this.item, this.daumConfig.post, call.zonecode)
				this.$set(this.item, this.daumConfig.address, call.address)
				this.daumPostUp = false
				this.$emit('setOverlay')
			}
		}
		,created: function(){
			this.getData()
		}
		,watch: {
			'item.userId': {
				handler: function(){
					this.item.isAutoUserId = false
					this.item.idCheck.result = false
				}
			}
			,'phoneNumbers.ceo': {
				deep: true
				,handler: function(call){
					this.$set(this.item, 'ceoMobilPhoneNumber', call.fir + call.sec + call.thi)
				}
			}
			,'phoneNumbers.manager': {
				deep: true
				,handler: function(call){
					this.$set(this.item, 'managerMobilPhoneNumber', call.fir + call.sec + call.thi)
				}
			}
			,personalNumber: {
				deep: true
				,handler: function(call){
					this.$set(this.item, 'businessNumber', call.fir + call.sec)
				}
			}
			,item: {
				deep: true
				,handler: function(call){
					//console.log('watch item')
					//console.log(call)
					let must = ['businessName', 'ceoName', 'ceoMobilPhoneNumber', 'bankCode', 'bankAccountHolder', 'bankAccountNumber']
					
					if(call.companyType == 'NON'){
						must.push('businessNumber')
					}else{
						must.push('businessNumber')
					}
					
					for(let i = 0; i < must.length; i++){
						if(!this.item[must[i]]){
							
//							this.$emit('setNotify', { error: 'error', message: must[i] + ' is required'})
							return false
						}
					}
					
					this.isSave = true
				}
			}
		}
	}
</script>























