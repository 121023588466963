<template>
	
	<div class="cont" id="p09" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0; background: none; z-index: 9999;">
		<div class="popup-list" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0;">
			<div class="popup type02" style="width: 550px; margin: 10% auto; transform: none; overflow: hidden; position: relative !important; z-index: 9999;">
				<div class="p-header">
					<div class="title">{{ program.name }}</div>
				</div>
				<div class="p-body">
					<div style="color: #00bfa5; font-size: 1.5em; font-weight: bold;">하위 대리점 등록이 완료되었습니다</div>
					<div class="c01">
						<div class="pc-body">
							<div class="item_list">
								<div class="item">
									<div class="title">아이디</div>
									<div class="desc">{{ item.userId }}</div>
								</div>
								<div class="item">
									<div class="title">임시 비밀번호</div>
									<div class="desc">{{ item.password }}</div>
								</div>
								<div class="item">
									<div class="title">대표자 휴대폰 번호</div>
									<div class="desc">{{ item.ceoMobilPhoneNumber }}</div>
								</div>
								<div class="item"
									v-if="item.managerMobilPhoneNumber"
								>
									<div class="title">담당자 휴대폰 번호</div>
									<div class="desc">{{ item.managerMobilPhoneNumber }}</div>
								</div>
							</div>
							<div class="p-text"> <!-- display 숨김처리 class "hide" -->
								아이디와 임시 비밀번호를 등록된 연락처로 전송하였습니다.
							</div>
						</div>
					</div>
				</div>
				<div class="p-button">
					<a @click="confirm"><div class="btn-submit">확인</div></a>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default{
		name: 'AgentPopupItem'
		,props: ['item']
		,data: function(){
			return {
				program: {
					name: '하위 대리점 등록 완료'
				}
			}
		}
		,methods: {
			confirm: function(){
				this.$emit('setOverlay', false)
				this.$router.push({ name: 'AgentSalesList' })
			}
		}
	}
</script>